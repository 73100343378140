import React from "react";

const Footer = () => {

  return(
    <footer>
      <p id='footer-message'>@ Noe Navarro 2023. All rights reserved</p>
    </footer>
  )
}

export default Footer;